import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import classnames from 'classnames';
import dayjs from 'dayjs';
const Carousel = dynamic(() => import('nuka-carousel'));

import TheHeader from '../components/common/TheHeader';
import ProfileImage from '../components/common/ProfileImage';
const GiveEndorsementButton = dynamic(() => import('../components/common/GiveEndorsementButton'));
const TokenInfo = dynamic(() => import('../components/common/TokenInfo'));
const TokenOverlay = dynamic(() => import('../components/common/TokenOverlay'));
const SlideUpPanel = dynamic(() => import('../components/common/SlideUpPanel'));
const EndorsementCard = dynamic(() => import('../components/common/EndorsementCard'));

import { triggerEvent, sendRequest, isMobile } from '../utils/global';
import { basicABI } from '../utils/nft';

import styles from '../sass/components/ProfileView.module.scss';

const ITEMS_PER_LOAD = 3;

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    wizardStep: store.data.wizardStep,
    isMobile: store.setup?.isMobile,
  };
};

class ProfileStaticView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarActive: false,
      unreadNotifications: false,
      profileImages: this.props.profileImages || [],
      user: this.props.user || null,
      profile: this.props.profile || null,
      endorsements: this.props.endorsements?.results || [],
      endorsementsCount: this.props.endorsements?.count || 0,
      endorsementsOffset: 0,
      noMoreEndorsements: false,
      connected: false,
      showTokenOverlay: false,
      showTokenSlideUp: false,
      isOwnProfile: this.props.user?.username === this.props.username,
      overlayData: {
        image: null,
        content: null,
        right: true,
      },
      viewCounts: null,
      isMounted: false,
    };
  }

  componentDidMount = async () => {
    this.loadUser();
    const isOwnProfile = this.props.user?.username === this.props.username;
    if (isOwnProfile && !this.profileImagesUploaded()) {
      this.reuploadImages();
    }
    if (typeof window !== 'undefined') {
      this.setState({ isMounted: true });
    }
    triggerEvent('removeLoad');
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.user !== this.props.user) {
      const isOwnProfile = this.props.user?.username === this.props.username;
      this.setState({
        user: this.props.user,
        isOwnProfile,
      });
      if (isOwnProfile && !this.profileImagesUploaded()) {
        this.reuploadImages();
      }
    }
    if (prevProps.username !== this.props.username) {
      this.loadUser();
    }
  }

  loadWeb3 = async () => {
    if (window.ethereum) {
      // window.web3 = new ethers.providers.Web3Provider(window.ethereum);
      window.web3 = new ethers.providers.JsonRpcProvider(process.env.NEXT_PUBLIC_ALCHEMY_URL);
      const enabled = await window.ethereum.enable();
      if (enabled) this.setState({ connected: true });
    } else if (window.web3) {
      // window.web3 = new ethers.providers.Web3Provider(window.web3.currentProvider);
      window.web3 = new ethers.providers.JsonRpcProvider(process.env.NEXT_PUBLIC_ALCHEMY_URL);
      this.setState({ connected: true });
    } else {
      if (!this.props.isMobile && !isMobile) window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  }

  loadUser = () => {
    sendRequest({
      method: `users/by/username/${this.props.username}`,
      type: 'GET',
      silent: true
    }).then(response => {
      this.setState({
        viewCounts: response.views_count,
      });
    });
  }

  profileImagesUploaded = () => {
    let valid = true;
    this.props.user.images.forEach(item => {
      if (!item.token_image?.w1400) valid = false;
    });
    triggerEvent('showSnackbar', [{text: 'We are still loading the PFP image for this profile, please give us a moment!'}]);
    return valid;
  }

  reuploadImages = () => {
    // TODO: Refactor, replace with a POST /users/me/refresh
    // Get rid of this once OpenSea API access is granted
    const images = this.props.user.images.map(item => ({ contract: item.contract, token_id: item.token_id, token_verification: item.token_verification || !item.skip_verification }));

    sendRequest({
      apiServer: false,
      method: `users/me?username=${this.props.user.username}`,
      type: 'PATCH',
      data: {
        images
      },
      silent: true,
    }).then((result) => {});
  }

  loadEndorsements = (concat = false) => {
    sendRequest({
      method: 'endorsements',
      type: 'GET',
      data: {
        recipient_id: this.state.profile._id,
        limit: ITEMS_PER_LOAD,
        // skip: this.state.endorsementsOffset,
        // no need to skip because we don't need load more for now
        skip: 0,
        published: true,
        unpublished: false
      }
    }).then(response => {
      const endorsements = concat ? response.results.concat(this.state.endorsements).filter((value, index, self) =>
        index === self.findIndex((t) => (
          t._id === value._id
        ))
      ) : response.results;
      this.setState({
        endorsementsCount: response.count,
        endorsements: endorsements,
        // endorsementsOffset: this.state.endorsementsOffset + response.results.length
        // no need to increment offset becase we don't need load more for now
      });
    });
  }

  isOwnProfile = () => {
    return this.props.profile?._id === this.props.user?._id;
  }

  goToEndorsementsPage = () => {
    this.props.router.push(`/${this.props.profile.username}/endorsements`);
  }

  showTokenInfo = async (i) => {
    const image = this.props.profile.images[i];
    let name = this.props.profile.images[i].contract_name;

    if (!name) {
      if (!window.ethereum && !window.web3) {
        window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        return false;
      }
      triggerEvent('addLoad');
      // const web3 = window.web3;
      // dynamically load ethers package
      const ethers = (await import('ethers')).ethers;
      // TODO: refactor -> use either ethers.getDefaultProvider or providers.AlchemyProvider
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(process.env.NEXT_PUBLIC_ALCHEMY_URL);
      const contract = new ethers.Contract(image.contract, basicABI, provider);
      name = await contract.name();
    }
    const isMobile = this.props.isMobile;

    let imageUrl = image.token_image?.w1400 || image.token_metadata?.image;
    if (!image.token_image && !image.token_metadata?.image) {
      // load image from profileImages
      imageUrl = this.props.profileImages[i].image;
    }

    const unverified = image.token_verification === false;

    if (name) {
      triggerEvent('removeLoad');
      this.setState({
        showTokenOverlay: !isMobile,
        showTokenSlideUp: isMobile,
        overlayData: {
          image: imageUrl,
          content: <TokenInfo unverified={unverified} mobile={isMobile} contractName={name} tokenId={image.token_id} metadata={image.token_metadata} />,
          right: true,
          scrollable: image.token_metadata?.description
        }
      });
    }
  }

  hideTokenOverlay = () => {
    this.setState({
      showTokenOverlay: false,
      showTokenSlideUp: false,
      overlayData: {
        image: null,
        content: null,
        right: true,
      }
    });
  }

  renderExperiencePeriod = (item) => {
    const start = dayjs(item.start_date).format('MMM YYYY');
    const end = item.end_date ? dayjs(item.end_date).format('MMM YYYY') : 'Current';
    return `${start} - ${end}`;
  }

  renderExperienceCard = (item) => {
    const imageUrl = item.twitter_profile_image.replace('_normal', '');
    return (
      <div className={styles.experience} key={item._id}>
        <div className={styles.header}>
          <div className={styles.image}>
            <Image
              src={imageUrl}
              alt={`@${item.twitter} profile image`}
              width='75'
              height='75'
              priority={true}
            />
          </div>
          <div className={styles.meta}>
            <div className={styles.position}>{item.job_title}</div>
            <div className={styles.period}>{this.renderExperiencePeriod(item)}</div>
          </div>
        </div>
        <div className={styles.project}>
          <div className={styles.name}>{item.twitter_name}</div>
          <div className={styles.description}>{item.twitter_description}</div>
          <a href={`https://twitter.com/${item.twitter}`} rel='noopener noreferrer' target='_blank'>
            <button className={styles.twitter}>@{item.twitter}</button>
          </a>
        </div>
      </div>
    );
  }

  goToUserProfilePage = (username) => {
    this.props.router.push(`/${username}`);
  }

  renderEndorsementCard = (item) => {
    const profileImage = item.author?.images.length > 0
      ? item.author?.images[0].token_image?.w106
        ? item.author?.images[0].token_image?.w106
        : item.author?.images[0].token_metadata?.image
      : null;

    return (
      <div key={item._id} className={styles.endorsement}>
        <EndorsementCard
          text={item.text}
          user={item.author}
          image={profileImage}
        />
      </div>
    );
  }

  renderEmptyProfileImage = (user) => {
    const isOwnProfile = this.isOwnProfile();

    const imageUrl = isOwnProfile
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/images/profile/default_pfp-own.png`
        : `${process.env.NEXT_PUBLIC_BASE_URL}/images/profile/default_pfp.png`;

    const isGenesisHolder = user.is_genesis_holder || user.plan?.name === 'genesis';

    return (
      <div
        className={classnames(styles.imageWrap, styles.i0)}
        key='profile_image_0'
      >
        <ProfileImage
          url={imageUrl}
          size='300'
          name={user.name}
          goldenBorder={isGenesisHolder}
        />
      </div>
    );
  }

  renderProfileImage = (item, i, user, isSlider = false) => {
    const isOwnProfile = this.isOwnProfile();

    const imageUrl = item.image
      ? item.image
      : isOwnProfile
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/images/profile/default_pfp-own.png`
        : `${process.env.NEXT_PUBLIC_BASE_URL}/images/profile/default_pfp.png`;

    const noClick = !item.image;

    if (isOwnProfile && !item.image) {
      triggerEvent('showSnackbar', [{text: 'Seems like your PFP isn\'t loading. Please try another PFP.'}]);
    }

    const isGenesisHolder = user.is_genesis_holder || user.plan?.name === 'genesis';

    return (
      <div
        className={classnames(styles.imageWrap, {
          [styles[`i${i}`]]: !isSlider,
        })}
        key={`profile_image_${i}`}
      >
        <ProfileImage
          url={imageUrl}
          size='300'
          name={user.name}
          goldenBorder={isGenesisHolder}
          onClick={noClick ? null : () => this.showTokenInfo(i)}
        />
      </div>
    );
  }

  disableVerticalScroll = () => {
    document.body.classList.add('noVerticalScroll');
  }

  enableVerticalScroll = () => {
    document.body.classList.remove('noVerticalScroll');
  }

  renderMobileProfileImages = (profileImages, user) => {
    const images = [...profileImages];
    const indexes = Object.keys(images);
    if (images.length === 3) {
      images.unshift(images.pop());
      indexes.unshift(indexes.pop());
    }
    return (
      <Carousel
        withoutControls
        slideIndex={1}
        slidesToShow={1.5}
        slidesToScroll={1}
        dragThreshold={0.4}
        onDragStart={this.disableVerticalScroll}
        onDragEnd={this.enableVerticalScroll}
        cellAlign='center'
      >
        {images.map((item, i) => this.renderProfileImage(item, indexes[i], user, true))}
      </Carousel>
    );
  }

  renderUserLink = (link, i) => {
    const url = link.url?.replace(/^(https?:\/\/)?/i, (a) => a || 'https://');
    return (
      <a key={`link_button_${i}`} href={url} rel='noopener noreferrer' target='_blank'>
        <button className={styles.website}>{link.name}</button>
      </a>
    );
  }

  render = () => {
    const { showTokenOverlay, showTokenSlideUp } = this.state;
    const profileImages = this.props.profileImages;
    const user = this.props.profile;
    const gotLinks = user?.links?.length > 0 || user?.twitter || user?.discord;
    const gotExperience = this.props.experience?.length > 0;
    const gotEndorsements = this.props.endorsements?.count > 0;
    const isOwnProfile = this.isOwnProfile();
    const isMoreEndorsements = this.props.endorsements?.results?.length < this.props.endorsements?.count;
    const profileImage = profileImages.length > 0 && profileImages[0].image || null;
    const isGenesisHolder = user.is_genesis_holder || user.plan?.name === 'genesis';
    let viewCounts = this.state.viewCounts || user.stats?.views || user.views_count;
    // user.views_count is deprecated
    viewCounts = viewCounts - 4 >= 1 ? viewCounts - 4 : 1;

    if (!user || !this.state.isMounted) return;

    return (
      <div
        className={classnames(styles.profilePage, {
          [styles.notGenesisHolder]: !isGenesisHolder
        })}
      >
        <TheHeader />
        <div className={classnames('container', styles.container)}>
          {isGenesisHolder && profileImages.length > 0 &&
          <div className={classnames(styles.profileImages, {
            [styles.mobile]: this.props.isMobile,
            [styles.singleImage]: profileImages.length === 1,
          })}>
            {this.props.isMobile && profileImages.length > 1
              ? this.renderMobileProfileImages(profileImages, user)
              : profileImages.map((item, i) => this.renderProfileImage(item, i, user))}
          </div>
          }
          {isGenesisHolder && (!profileImages || profileImages.length === 0) &&
          <div className={classnames(styles.profileImages, {
            [styles.mobile]: this.props.isMobile,
          })}>
            {this.renderEmptyProfileImage(user)}
          </div>
          }
          <div className={styles.flex}>
            <div className={styles.left}>
              {!isGenesisHolder && profileImages.length > 0 &&
                <div className={classnames(styles.profileImages, {
                  [styles.mobile]: this.props.isMobile,
                  [styles.singleImage]: profileImages.length === 1,
                })}>
                  {this.renderProfileImage(profileImages[0], 0, user)}
                </div>
              }
              {!isGenesisHolder && (!profileImages || profileImages.length === 0) &&
                <div className={classnames(styles.profileImages, {
                  [styles.mobile]: this.props.isMobile,
                })}>
                  {this.renderEmptyProfileImage(user)}
                </div>
              }
              <div className={classnames(styles.blockTitle, {
                [styles.genHolder]: isGenesisHolder,
                [styles.ascHolder]: user.is_ascended_holder,
              })}>{user.name}</div>
              {user.summary && <div className={classnames(styles.blockTitle, styles.summary)}>{user.summary}</div>}
              {user.bio && <div className={styles.bio}>{user.bio}</div>}
              {gotLinks &&
              <div className={styles.links}>
                {user.twitter && <a href={`https://twitter.com/${user.twitter.username}`} rel='noopener noreferrer' target='_blank'><button className={styles.twitter}>@{user.twitter.username}</button></a>}
                {user.discord && <a href={`https://discord.com/users/${user.discord.user_id}`} rel='noopener noreferrer' target='_blank'><button className={styles.discord}>@{user.discord.username}</button></a>}
                {user.links.length > 0 && user.links.map((link, i) => this.renderUserLink(link, i))}
              </div>
              }
              <div className={styles.stats}>
                <span className={styles.joined}>Joined {dayjs(user.created_at).format('MMM YYYY')}</span>
                <span className={styles.views}>{viewCounts} view{viewCounts > 1 ? 's' : null}</span>
                {user.timezone && <span className={styles.timezone}>{user.timezone}</span>}
              </div>
              {user.roles.length > 0 && <div className={styles.blockTitle}>Roles</div>}
              {user.roles.length > 0 && 
              <div className={styles.roles}>
                {user.roles.map(role => <span key={role._id} className={styles.role}>{role.name}</span>)}
              </div>
              }
              {!isOwnProfile && <GiveEndorsementButton profile={user} image={profileImage} />}
            </div>
            {(gotEndorsements || gotExperience) &&
            <div className={classnames(styles.right)}>
              {gotExperience && <div className={styles.blockTitle}>Experience</div>}
              {gotExperience && 
              <div className={styles.experiences}>
                {this.props.experience.map(this.renderExperienceCard)}
              </div>
              }
              {this.props.endorsements?.count > 0 && <div className={styles.blockTitle}>{this.props.endorsements?.count} Endorsement{this.props.endorsements?.count > 1 && 's'}</div>}
              {this.props.endorsements?.count > 0 && 
              <div className={styles.endorsements}>
                {this.props.endorsements.results.map(this.renderEndorsementCard)}
              </div>
              }
              {isMoreEndorsements && <button className={styles.seeMoreEndorsements} onClick={this.goToEndorsementsPage}>See all endorsements</button>}
            </div>
            }
          </div>
        </div>
        <TokenOverlay show={showTokenOverlay} onClose={this.hideTokenOverlay} {...this.state.overlayData} />
        <SlideUpPanel show={showTokenSlideUp} onClose={this.hideTokenOverlay}>{this.state.overlayData.content}</SlideUpPanel>
      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(ProfileStaticView));
import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { connect } from 'react-redux';

import ProfileStaticView from '../../components/ProfileStaticView';
import PageLoadPlaceholder from '../../components/common/PageLoadPlaceholder';

import { basicABI, getTokenMetadata } from '../../utils/nft';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  };
};

const PublicProfilePage = (props) => {
  const router = useRouter();
  
  if (router.isFallback) {
    return (
      <PageLoadPlaceholder />
    );
  }

  const { username } = router.query;
  const title = `${props.profile.name || username} | PNTHN`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={`View ${props.profile.name || username}'s profile on PNTHN, The Professional Network for the Web3 space. ${props.profile.summary}, ${props.profile.bio}`} />
        <meta property='og:url' content={`https://pnthn.xyz/${username}`} />
        <meta property='og:title' content={`${props.profile.name || username} | PNTHN`} />
        <meta property='og:image' content={props.profile.og_image || props.profileImages[0]?.image} />
        <meta property='og:description' content={`View ${props.profile.name || username}'s profile on PNTHN, The Professional Network for the Web3 space. ${props.profile.summary}, ${props.profile.bio}`} />
        <meta name='twitter:title' content={`${props.profile.name || username} | PNTHN`} />
        <meta name='twitter:description' content={`View ${props.profile.name || username}'s profile on PNTHN, The Professional Network for the Web3 space. ${props.profile.summary}, ${props.profile.bio}`} />
        <meta name='twitter:image' content={props.profile.og_image || props.profileImages[0]?.image} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='image' content={props.profile.og_image || props.profileImages[0]?.image} />
        <link rel='preconnect' href='https://imagedelivery.net' />
        <link rel='dns-prefetch' href='https://imagedelivery.net' />
        <link rel='preconnect' href='https://pbs.twimg.com' />
        <link rel='dns-prefetch' href='https://pbs.twimg.com' />
      </Head>
      <ProfileStaticView
        username={username}
        {...props}
      />
    </>
  )
}

const loadProfileImages = async (profile) => {
  if (!profile || !profile?.images) return;

  const images = [];
  let i = 0;
  for (const item of profile?.images) {
    if (item.token_image) {
      // 2. try to load from our CDN
      images[i] = { ...item.token_image, image: item.token_image?.w500 };
    } else if (item.token_metadata?.image) {
      // 3. get image from metadata
      images[i] = item.token_metadata;
    } else if (item.token_uri) {
      // 4. load image by URI
      const metadata = await getTokenMetadata(item.token_uri);
      if (metadata) images[i] = metadata;
    } else {
      // 5. load token by ID and then load image by URI
      const ethers = (await import('ethers')).ethers;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(item.contract, basicABI, provider);
      contract.tokenURI(item.token_id).then((tokenURI) => {
        getTokenMetadata(tokenURI).then(metadata => {
          if (metadata) images[i] = metadata;
        });
      });
    }
    i++;
  }

  return images;
}

export async function getStaticProps(ctx) {
  const profileRes = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/users/by/username/${ctx.params.username}`);
  const profile = await profileRes.json();

  if (!profile || profile.error) {
    return {
      redirect: {
        permanent: false,
        destination: '/not-found'
      },
    }
  }

  const experienceRes = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/experience/?user_id=${profile._id}`);
  const experience = await experienceRes.json();

  const endorsementsRes = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/endorsements/?recipient_id=${profile._id}`);
  const endorsements = await endorsementsRes.json();

  const profileImages = await loadProfileImages(profile);

  return {
    props: {
      profile,
      experience,
      endorsements,
      profileImages,
      isUser: true,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 10, // In seconds
  }
}

export async function getStaticPaths() {

  const paths = [];

  // We'll pre-render only these paths at build time.
  // { fallback: blocking } will server-render pages
  // on-demand if the path doesn't exist.
  return { paths, fallback: true };
}

export default connect(mapStoreToProps)(PublicProfilePage);

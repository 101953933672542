import React from 'react';

import styles from '../../sass/components/common/PageLoadPlaceholder.module.scss';

const PageLoadPlaceholder = () => {
  return (
    <div className={styles.pageLoadPlaceholder}>
      <div className={styles.loadingContainer}>
        <div className={styles.spinner} />
        <div className={styles.text}>Loading</div>
      </div>
    </div>
  );
}

export default PageLoadPlaceholder;
